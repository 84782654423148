/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import toast from 'react-hot-toast';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import dompurify from 'dompurify';

import {
  orderInfoHandler,
  returnClkEvent,
  cbClkEvent,
  addProduct,
  removeProduct,
  returnResonsHandler,
  quantityChange,
  stepChange,
  actionChange,
  dateChange,
  addressChange,
  returnHandler,
  checkPincodeServiceability,
  preSignedUrl,
  uploadFileToS3,
  setImages,
  resetImages,
  uploadImageConfig,
  resetReturnView,
  setStoreCreditInfo,
  fileUploadCompleted,
  selectRefundMethod,
  getServiceableAreas,
  categoryBasedReasonsGetter
} from '../../service';

import RequestForm from './forms/request';
import AddressForm from './forms/address';
import BankDetailForm, { noBackToSrcDomains } from './forms/bankDetail';
import NotFound from '../NotFound';
import {
  address as addressObj,
  calendarDates,
  currencyMapper,
  bfl,
  toastStyle,
  noServiceabilityCheck
} from '../../utils/entity_mapping';
import actionMessages from '../../utils/messages';
import PlacedOrder from './placedOrder';
import { strings } from '../../utils/localizedStrings';
import { openUrl, zipyInit } from '../../utils/helper';
import MultipleShipments from './multipleShipments';
import { bankDetailNotRequiredForAdminDomains } from '../exchange/defaultValues';
import { bankFormValidation } from '../exchange/forms/validate';
import { refundTypeCodes } from './defaultValues';
// import { infoIcon } from '../exchange/utils/svgs';

const phonePattern = new RegExp(/^\d{10}$/);
const bflPhonePattern = new RegExp(/^5\d{8}$/); // check without country code
const bflPhonePatternSG = new RegExp(/^\d{10}$/); // check without country code - Singapore
// const bflPhonePattern = new RegExp(/^\+[0-9]{12}$/); // checks with country code
const pincodePattern = new RegExp(/^\d{6}$/);
const pincodePatternSG = new RegExp(/^\d{5,6}$/);
const emailPattern = new RegExp(/^.+@.+\..+$/);

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const MyLoader = props => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="150" y="15" rx="3" ry="3" width="250" height="12" />
    <rect x="150" y="40" rx="3" ry="3" width="250" height="12" />
    <rect x="10" y="10" width="100" height="120" />
  </ContentLoader>
);

const subdomains = [
  'abfrl-test',
  'peterengland',
  'abfrl-test2',
  'pantaloons',
  'allensolly',
  'louisphilippe',
  'vanheusenindia',
  'vanheusenintimates',
  'thecollective',
  'pered',
  'aeo',
  'forever21',
  'planetfashion',
  'abof',
  'simoncarter',
  'reebok'
];

class Order extends Component {
  state = {
    initialValues: { reason: null, comment: '' },
    address: Object.assign(addressObj),
    isSelfShipped: false,
    tempPincodeData: { city: '', state: '', country: '', pincode: '' },
    dateMeta:
      calendarDates({ itr: 6, subdomain: this.props.user.subdomain }) || [],
    source: '',
    token: '',
    sku: '',
    isSubmitting: false,
    selfShiptext: '',
    filteredReason: { isFetching: true, error: false, list: [] }
  };
  componentDidMount() {
    const {
      user,
      orderInfoHandler,
      location,
      returnResonsHandler,
      orderInfo,
      getServiceableAreas
    } = this.props;
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    this.setState({
      selfShiptext: this.props.tracking?.theme?.meta_data?.udf?.filter(
        item => item.key === 'selfShipText'
      )?.[0]?.value
    });

    const query = {
      key: searchParams.get('security_key'),
      waybill: searchParams.get('waybill'),
      orderId: searchParams.get('order_id'),
      referenceNumber: searchParams.get('reference_number'),
      mobile: searchParams.get('mobile'),
      email: searchParams.get('email'),
      domain: user.subdomain,
      source: searchParams.get('source'),
      token: searchParams.get('token'),
      sku: searchParams.get('sku'),
      awbs: searchParams.get('awbs')
    };
    this.setState({
      source: searchParams.get('source'),
      token: searchParams.get('token'),
      sku: searchParams.get('sku')
    });

    if (query.orderId) {
      query.orderId = encodeURIComponent(query.orderId);
    }
    if (!orderInfo.loggedUser) {
      orderInfoHandler(query).then(res => {
        if (res?.meta?.status === 200 && query.sku) {
          // If searchParams has correct sku and Product Qualified Return Quantity > 0,
          // we are switching the view from product listing to reason fill form.
          const product = res.result.item_info.filter(
            product =>
              product.shipment_eligible_for_return &&
              product.eligible_quantity_can_be_return > 0 &&
              product.sku === query.sku
          );
          if (product.length > 0) {
            this.props.cbClkEvent(0);
          }

          zipyInit(res?.result, user);
        }
      });
    }
    returnResonsHandler(user.subdomain);
    if (this.props.tracking?.theme?.meta_data?.show_configured_location) {
      let securityKey = '';
      if (searchParams.get('security_key')) {
        securityKey = searchParams.get('security_key');
      } else {
        securityKey = searchParams.get('key') ?? '';
      }
      const queryObj = {
        security_key: securityKey,
        waybill: searchParams.get('waybill'),
        subdomain: user.subdomain
      };
      getServiceableAreas(queryObj);
    }
  }

  componentWillUnmount = () => {
    this.props.resetReturnView();
  };

  componentDidUpdate() {
    // to handle multiple shipments
    const { hasMultipleShipments } = this.props.orderInfo;
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    if (
      hasMultipleShipments &&
      !searchParams.has('awbs') &&
      !searchParams.has('security_key') &&
      this.props.orderInfo.awbs?.length > 1 &&
      this.props.orderInfo.shipmentIDs?.length > 1
    ) {
      const awbs = this.props.orderInfo.awbs.join(',');
      const shipmentIDs = this.props.orderInfo.shipmentIDs.join(',');
      this.props.history.push(
        `/returns?awbs=${awbs}&security_key=${shipmentIDs}`
      );
    }
  }

  requestReasonChange = e => {
    this.props.uploadImageConfig(e);
  };

  onChange = (e, productId) => {
    const { exchangeConfig, user, reasons } = this.props;
    this.setState({
      initialValues: {
        reason: null,
        subreason: null,
        comment: null
      }
    });

    if (productId && exchangeConfig?.categoryBasedReason) {
      categoryBasedReasonsGetter(productId, user.subdomain).then(res => {
        if (res?.returnReasons?.list?.length > 0) {
          this.setState({
            filteredReason: {
              isFetching: res.returnReasons.isFetching,
              error: res.returnReasons.error,
              list: res.returnReasons.list
            }
          });
        }
      });
    } else {
      this.setState({
        filteredReason: reasons
      });
    }
    this.props.resetImages();
    this.props.cbClkEvent(Number(e.target.value));
  };

  backToList = () => {
    const { cbClkEvent, orderInfo } = this.props;
    // Skipping Summary page if we get sku in the searchParam
    if (this.state.sku) {
      if (
        orderInfo.products.length > 0 &&
        orderInfo.request[orderInfo.products[0].id].isSubmitted
      ) {
        orderInfo.action.activeIndex = -1;
        orderInfo.action.canGoToAddress = true;
        actionChange(orderInfo);
        this.goToStep(null, 2);
      }
    } else {
      cbClkEvent(orderInfo.action.activeIndex);
    }
  };

  editRequest = (e, i) => {
    const { products, request } = this.props.orderInfo;
    this.props.cbClkEvent(i);
    const product = products[i];
    if (request.hasOwnProperty(product.id)) {
      this.setState({
        initialValues: {
          reason: request[product.id].reasonId,
          subreason: request[product.id].subreason,
          comment: request[product.id].comment
        }
      });
      this.props.resetImages();
      this.props.setImages(request[product.id].files);
    }
  };

  removeRequest = (e, i) => {
    let { orderInfo } = this.props;
    const product = orderInfo.products[i];
    if (orderInfo.request.hasOwnProperty(product.id)) {
      delete orderInfo.request[product.id];
      if (Object.keys(orderInfo.request).length === 0) {
        orderInfo.action.canGoToAddress = false;
      }
      this.props.removeProduct(orderInfo);
    }
  };

  returnClkEvent = () => {
    this.props.returnClkEvent();
  };

  uploadImage = (item, idx) => {
    return this.props.preSignedUrl(idx).then(res => {
      if (res?.meta?.status === 200) {
        return this.props.uploadFileToS3(idx);
      }
    });
  };

  addProductForReturn = (reason, subreason, comment) => {
    const { products, action, request, isCaptureImage } = this.props.orderInfo;
    let toastId = 'imageUploadToast',
      isUploadComplete = false;
    if (isCaptureImage && this.props.file.list.length < reason.imageQuantity) {
      return toast.error(actionMessages.noImage);
    }
    if (isCaptureImage && reason.imageQuantity > 0) {
      // if upload taking more than 5 seconds, show different text
      toast.loading('Uploading images...', { id: toastId, duration: Infinity });
      setTimeout(() => {
        if (!isUploadComplete)
          toast.loading('taking longer than expected, please wait...', {
            id: toastId,
            ...toastStyle.error
          });
      }, 5000);
    }
    const promiseArray = this.props.file.list.map(this.uploadImage);
    return Promise.all(promiseArray).then(async res => {
      isUploadComplete = true;
      toast.dismiss(toastId);
      if (!res?.every(resp => resp?.status === 204)) {
        this.props.fileUploadCompleted();
        toast.error('Image upload failed please try again!');
        return res;
      }
      if (res.length > 0) {
        this.props.fileUploadCompleted();
        await sleep(100);
      } else {
        await sleep(100);
      }
      // const { reason, comment } = this.props.requestForm;
      const itemId = products[action.activeIndex].id;
      request[itemId] = Object.assign(request[itemId], {
        reasonId: reason,
        subreason: subreason,
        comment,
        isSubmitted: true,
        product_image_link: this.props.file.list
          .map(item => item.s3Url)
          .join(','),
        files: this.props.file.list
      });

      this.props.addProduct(request);
      if (this.state.sku) {
        this.goToStep(null, 2);
      }
    });
  };

  quantityChange = (e, method) => {
    const { orderInfo, tracking } = this.props;
    if (tracking.theme.meta_data && tracking.theme.meta_data.quantity_lock) {
      return;
    }
    const { products, action, request } = orderInfo;
    const product = products[action.activeIndex];
    if (request.hasOwnProperty(product.id)) {
      if (
        method === 'add' &&
        request[product.id].quantity < product.eligible_quantity_can_be_return
      ) {
        request[product.id].quantity += 1;
      }
      if (method === 'sub' && request[product.id].quantity > 1) {
        request[product.id].quantity -= 1;
      }
    }
    this.props.quantityChange(request);
  };

  goToStep = (e, current) => {
    const { step, orderType, storeCredit } = this.props.orderInfo;
    if (current > step.reached) {
      step.reached = current;
    }
    step.current = current;
    this.props.stepChange(step);
    if (step.current > 1) {
      const exsitingRequests = this.props.orderInfo.request;
      let request = {};
      for (const key in exsitingRequests) {
        if (
          exsitingRequests.hasOwnProperty(key) &&
          exsitingRequests[key].isSubmitted
        ) {
          request[key] = exsitingRequests[key];
        }
      }
      this.props.quantityChange(request);
    }
    if (step.current === 2) {
      this.props.setStoreCreditInfo();
      if (
        orderType === 'PREPAID' &&
        storeCredit.totalAmountPaid === 0 &&
        storeCredit.totalCredit > 0
      ) {
        // this.props.selectRefundMethod('credit');
      }
    }
  };

  backToStep = (e, current) => {
    const { orderInfo, stepChange, actionChange } = this.props;
    const { step, action } = orderInfo;
    step.reached = current;
    step.current = current;
    stepChange(step);
    if (current < 3) {
      action.address.select = -1;
      action.address.edit = -1;
      action.address.isEditting = false;
      actionChange(action);
      this.setState({ isSelfShipped: false });
    }
    // Skipping Summary page if we get sku in the searchParam
    if (this.state.sku && step.reached === 1) {
      this.editRequest(e, 0);
      action.activeIndex = 0;
      actionChange(action);
    }
    this.setState({ address: addressObj }); // resetting address to empty when back button is clicked
  };

  addressSelection = (e, index) => {
    e.preventDefault();
    const self = this;
    const { orderInfo, tracking } = this.props;
    const { subdomain } = this.props.user;
    const { action, addresses } = orderInfo;
    const { config } = tracking.theme;
    let address = addresses[index];
    const isBFL = bfl.domains.indexOf(subdomain) > -1;

    if (
      (!config.form_editable &&
        noServiceabilityCheck.countries.includes(
          (config?.country_code ?? '').toLowerCase()
        )) ||
      isBFL ||
      noServiceabilityCheck.brands.includes(subdomain)
    ) {
      action.address.select = index;
      return this.props.actionChange(action);
    }
    if (!address.name || !address.phone) {
      return toast.error(actionMessages.addressForm.missingFields);
    }
    if (address.email && !emailPattern.test(address.email)) {
      return toast.error(actionMessages.email.blank);
    }
    this.props.checkPincodeServiceability(address.pincode).then(res => {
      const { city, state, country } = res.result.pincode_data;
      if (city) {
        address.city = city;
      }
      if (state) {
        address.state = state;
      }
      if (country) {
        address.country = country;
      }
      if (
        !address.address ||
        !address.phone ||
        !address.city ||
        !address.state ||
        !address.country ||
        !address.pincode
      ) {
        return toast.error(actionMessages.addressForm.missingFields);
      }
      if (res.result.pincode_serviceable) {
        self.setState({ isSelfShipped: false });
      } else {
        self.setState({ isSelfShipped: true });
        // action.address.select = -1;
        // this.props.actionChange(action);
        toast.error(
          this.state.selfShiptext ?? actionMessages.pincode.notServiceable
        );
      }
      action.address.select = index;
      this.props.actionChange(action);
    });
  };

  addressEdit = (e, index) => {
    const { action, addresses } = this.props.orderInfo;
    action.address.edit = index;
    action.address.isEditting = true;
    this.props.actionChange(action);
    if ((action, addresses.length === action.address.edit)) {
      const orderAddress = addresses[addresses.length - 1] ?? {};
      // Prefilling some values but not all
      addresses[action.address.edit] = Object.assign({
        isSubmitted: false,
        name: orderAddress.name ?? '',
        address: '',
        email: orderAddress.email ?? '',
        landmark: '',
        city: orderAddress.city ?? '',
        state: orderAddress.state ?? '',
        country: orderAddress.country ?? '',
        pincode: '',
        phone: ''
      });
    }
    if (bfl.domains.indexOf(this.props.user.subdomain) > -1) {
      addresses[action.address.edit].phoneCode =
        bfl.phoneCodes[addresses[addresses.length - 1]?.country]?.code ?? '';
    }

    if (this.props.user.subdomain === 'trackmyorder') {
      addresses[action.address.edit].email = '';
    }
    this.setState({ address: addresses[action.address.edit] });
    this.props.addressChange(addresses);
  };

  backToAddresses = () => {
    const { action, addresses } = this.props.orderInfo;
    if (addresses[action.address.edit]?.isSubmitted === false) {
      addresses.splice(action.address.edit, 1);
    }
    action.address.edit = -1;
    action.address.isEditting = false;
    this.props.actionChange(action);
    this.props.addressChange(addresses);
    this.setState({ address: Object.assign(addressObj) });
  };

  pincodeChange = pincode => {
    const { action } = this.props.orderInfo;
    let addresses = this.props.orderInfo.addresses;
    // const { pincode } = this.props.addressForm;
    if (pincodePattern.test(pincode)) {
      this.props.checkPincodeServiceability(pincode).then(res => {
        const { result } = res;
        if (result.pincode_serviceable) {
          let stateAddress = this.state.address;
          let address = addresses[action.address.edit];
          const { city, state, country } = result.pincode_data;
          stateAddress = Object.assign(stateAddress, {
            city: city || '',
            state: state || '',
            country: country || '',
            pincode
          });
          this.setState({ address: stateAddress });
          address = Object.assign(address, { city, state, country, pincode });
          addresses[action.address.edit] = address;
          this.props.addressChange(addresses);
        } else {
          // this.setState({ isSelfShipped: true });
          toast.error(
            this.state.selfShiptext ?? actionMessages.pincode.notServiceable
          );
        }
        this.setState({
          isSelfShipped: result.pincode_serviceable ? false : true
        });
      });
    }
  };

  addressFormSubmit = _address => {
    return sleep(200).then(() => {
      const {
        addresses,
        action,
        locationsFound,
        locations
      } = this.props.orderInfo;
      action.address.select = action.address.edit;
      const { lang } = this.props.user;
      let {
        name,
        email,
        address,
        landmark,
        city,
        state,
        country,
        pincode,
        phone,
        postal_code
      } = _address;
      const allowChecks = bfl.domains.indexOf(this.props.user.subdomain) === -1; // BFL related. Will remove this logic in a different PR and create separate order page for BFL to avoid these multiple checks
      const isBFLSingapore = bfl.showNormalPhoneNumber.includes(
        (country ?? '').toLowerCase()
      );
      if (!phonePattern.test(phone) && allowChecks) {
        return toast.error(actionMessages.phone.blank);
      } else if (!allowChecks) {
        // Singapore
        if (isBFLSingapore && !bflPhonePatternSG.test(phone)) {
          return toast.error('Please enter a 10 digit phone number');
        }
        // Middle East countries
        if (!isBFLSingapore && !bflPhonePattern.test(phone)) {
          return toast.error(
            strings[lang]?.phoneFormat('5XXXXXXXX') ??
              'Please enter a phone number in the format 5XXXXXXXX without the Phone Code'
          );
        }
      }
      if (!pincodePattern.test(pincode) && allowChecks) {
        return toast.error(actionMessages.pincode.blank);
      }
      if (!pincodePatternSG.test(pincode) && !allowChecks && isBFLSingapore) {
        return toast.error(actionMessages.pincode.sg);
      }
      if (email && !emailPattern.test(email)) {
        return toast.error(actionMessages.email.blank);
      }
      if (locationsFound && locations && !isBFLSingapore && !postal_code) {
        return toast.error(actionMessages.area.blank);
      }
      this.props.checkPincodeServiceability(pincode).then(res => {
        const { result } = res;
        if (allowChecks) {
          if (
            !result.pincode_serviceable &&
            this.props.tracking.theme.config &&
            !this.props.tracking.theme.config.self_ship_allowed &&
            this.props.user.subdomain !== 'kushals'
            // &&
            // country && !noServiceabilityCheck.includes((country ?? '').toLowerCase())
          ) {
            toast.error(actionMessages.noShipping);
          } else if (
            !result.pincode_serviceable &&
            this.props.user.subdomain !== 'kushals'
          ) {
            toast.error(
              this.state.selfShiptext ?? actionMessages.pincode.notServiceable
            );
          }
        }
        // else {
        addresses[action.address.edit] = {
          name,
          email,
          address,
          landmark,
          city,
          state,
          country,
          pincode: !allowChecks && !isBFLSingapore ? postal_code : pincode,
          phone,
          // postal_code,
          isSubmitted: true
        };
        this.props.addressChange(addresses);
        action.address.edit = -1;
        action.address.isEditting = false;
        this.props.actionChange(action);
        let isSelfShipped = false;
        if (allowChecks) {
          isSelfShipped =
            result.pincode_serviceable ||
            this.props.user.subdomain === 'kushals'
              ? false
              : true;
        } else {
          isSelfShipped =
            bfl.hasSelfShip.indexOf(this.state.address.country) > -1;
        }
        this.setState({ isSelfShipped });
        // }
      });
    });
  };

  onCalendarChange = date => {
    this.props.dateChange(date);
  };

  submitReturn = () => {
    this.setState({ isSubmitting: true });
    const { isSelfShipped, source, token } = this.state;
    const {
      request,
      addresses,
      action,
      date,
      shipment_uuid,
      awb,
      reference_number,
      bankDetail,
      upiDetails,
      isErrorInCOD,
      orderType,
      storeCredit,
      confirmedRefundPayment,
      isCaptureImage
      // locationsFound,
      // locations
    } = this.props.orderInfo;
    const { meta_data, udfKeys } = this.props.tracking.theme;
    let error = {
      isError: false,
      message: ''
    };
    let address = {};
    if (addresses.length - 1 >= action.address.select) {
      address = addresses[action.address.select];
    }
    if (
      this.props.user.subdomain === 'hamiltoncollection' &&
      addresses.length > 0
    ) {
      address = addresses[0];
    }
    var query = {
      refund_to_upi: false,
      refund_to_bank_account: false,
      return_info: {
        return_name: address?.name ?? '',
        return_email: address?.email ?? '',
        return_address: address?.address ?? '',
        return_landmark: address?.landmark ?? '',
        return_city: address?.city ?? '',
        return_state: address?.state ?? '',
        return_country: address?.country ?? '',
        return_pin_code: address?.pincode ?? '',
        return_phone_number: address?.phone ?? '',
        return_pickup_date: isSelfShipped
          ? null
          : moment(date).format('YYYY-MM-DD') ?? '',
        reference_number
      },
      shipment_uuid,
      awb,
      domain: this.props.user.subdomain,
      source,
      token
    };
    // if (locationsFound && locations && address.postal_code) {
    //   query.return_info.postal_code = address.postal_code; // for BFL
    // }
    if (meta_data && meta_data.remove_pickup_date) {
      query.return_info.return_pickup_date = null;
      query.return_info.return_pickup_date = moment()
        .add('days', 1)
        .format('YYYY-MM-DD');
    }
    query.item_info = Object.keys(request).map(item => {
      if (
        request[item].reasonId.imageQuantity >
          request[item].product_image_link.split(',') &&
        isCaptureImage
      ) {
        error.isError = true;
        error.message =
          'Please upload an image to complete the form. An image is needed to proceed with your submission.';
      }
      return {
        id: Number(item),
        quantity: request[item].quantity,
        reason_id: request[item].reasonId.value,
        sub_reason: request[item]?.subreason?.label ?? '',
        return_reason_data: request[item].reasonId.label,
        reason_comment: request[item].comment,
        product_image_link: request[item].product_image_link
          ? request[item].product_image_link
          : null
      };
    });
    if (
      storeCredit.allInCredit ||
      (orderType === 'PREPAID' &&
        storeCredit.totalAmountPaid === 0 &&
        storeCredit.totalCredit > 0)
    ) {
      query.return_amount_in_store_credit = true;
    }
    query.self_shipped = isSelfShipped ? true : false;
    if (
      isErrorInCOD &&
      confirmedRefundPayment === refundTypeCodes.bankDetails
    ) {
      if (
        bankDetail.name ||
        bankDetail.accountNumber ||
        bankDetail.ifsccode ||
        bankDetail.bankName ||
        bankDetail.branchName
      ) {
        this.setState({ isSubmitting: false });
        return toast.error(actionMessages.return.bankDetail);
      }
    } else if (
      meta_data &&
      meta_data.capture_bank_detail &&
      confirmedRefundPayment === refundTypeCodes.bankDetails &&
      !(
        bankDetailNotRequiredForAdminDomains.includes(
          this.props.user.subdomain
        ) &&
        this.props.page.source.toLowerCase() === 'dashboard' &&
        Object.keys(bankFormValidation(bankDetail)).length > 0
      )
    ) {
      query.refund_to_bank_account = true;
      query.bank_detail = {
        name: !udfKeys?.returnsGetShortBankDetails ? bankDetail.name : 'NA',
        account_number: bankDetail.accountNumber,
        ifsccode: !udfKeys?.returnsGetShortBankDetails
          ? bankDetail.ifsccode
          : 'NA',
        bank_name: bankDetail.bankName,
        branch_name: !udfKeys?.returnsGetShortBankDetails
          ? bankDetail.branchName
          : 'NA'
      };
    }

    if (
      confirmedRefundPayment === refundTypeCodes.source &&
      orderType === 'PREPAID' &&
      !noBackToSrcDomains.includes(this.props.user.subdomain)
    ) {
      query.back_to_source = true;
    }

    if (
      confirmedRefundPayment === refundTypeCodes.upiDetails &&
      meta_data &&
      meta_data.capture_upi_detail
    ) {
      if (!upiDetails.upiName || !upiDetails.upiId) {
        this.setState({ isSubmitting: false });
        return toast.error(actionMessages.return.UPIDetails);
      } else {
        query.refund_to_upi = true;
        query.bank_detail = {
          payment_mode: upiDetails.upiName,
          phone_number_vpa: upiDetails.upiId
        };
      }
    }

    if (Object.values(refundTypeCodes).includes(confirmedRefundPayment)) {
      query.refund_option_name = confirmedRefundPayment;
    }

    const toastId = toast.loading(actionMessages.inProgress);

    if (error.isError) {
      toast.error(error.message, { id: toastId });
    }

    this.props.returnHandler(query).then(res => {
      if (res.meta && res.meta.success) {
        toast.success(
          udfKeys?.returnSubmitToast ?? actionMessages.return.submit,
          { id: toastId }
        );
        const itemInfoIds = query.item_info.map(item => item.id);
        this.props.stepChange({ reached: 1, current: 1 });
        this.props.history.push(
          `/returns/status?security_key=${query.shipment_uuid}&waybill=${
            query.awb
          }&item_info_id=${itemInfoIds.join(
            ','
          )}&return_id=${res.result.return_id.join(',')}${
            source ? `&source=${source}` : ''
          }${token ? `&token=${token}` : ''}`
        );
      } else {
        this.setState({ isSubmitting: false });
        if (res.meta && res.meta.message) {
          toast.error(res.meta.message, { id: toastId });
        } else {
          toast.error(res.message, { id: toastId });
        }
      }
    });
  };

  pushRoute = (e, route) => {
    this.props.history.push(route);
  };

  toggleSelfShipped = () => {
    this.setState({ isSelfShipped: false });
  };

  goHome = () => {
    const { routePrefix } = this.props.user;
    this.props.stepChange({ reached: 1, current: 1 });
    return sleep(100).then(() => {
      this.props.history.push(
        `${routePrefix ? `/${routePrefix}` : ''}/returns`
      );
    });
  };

  submitSelfShip = (e, current) => {
    const { orderInfo, tracking } = this.props;
    if (tracking.theme.config && !tracking.theme.config.self_ship_allowed) {
      return toast.error(actionMessages.selfShip);
    }
    const {
      action
      // addresses
    } = orderInfo;
    // commented below code for showing self ship always
    // if (
    //   action.address.isEditting &&
    //   !addresses[action.address.edit].isSubmitted
    // ) {
    //   addresses.splice(action.address.edit, 1);
    // }
    action.address.select = 0;
    action.address.edit = -1;
    action.address.isEditting = false;
    this.props.actionChange(action);
    this.setState({ isSelfShipped: true, address: Object.assign(addressObj) });

    this.goToStep(e, current);
  };

  goToMyOrder = () => {
    const { meta_data } = this.props.tracking.theme;
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(meta_data.my_order_url);
    } else {
      window.location.href = meta_data.my_order_url;
    }
  };

  onSelect = (uuid, awb) => {
    if (uuid && awb) {
      this.props.history.push(`/returns?security_key=${uuid}&waybill=${awb}`);
    }
  };

  render() {
    const { orderInfo, tracking, file, user, page } = this.props;
    const {
      action,
      request,
      step,
      addresses,
      products,
      hasMultipleShipments,
      shipments
    } = orderInfo;
    let formData = { reason: null, subreason: '', comment: '' };
    const { isSelfShipped } = this.state;
    if (action.activeIndex !== -1 && products.length > 0) {
      formData = {
        reason: request[products[action.activeIndex].id].reasonId,
        subreason: request[products[action.activeIndex].id].subreason,
        comment: request[products[action.activeIndex].id].comment
      };
    }
    let filteredProducts = [];
    if (step.current > 1) {
      filteredProducts = JSON.parse(JSON.stringify(orderInfo.products)).filter(
        product => {
          if (request[product.id] && request[product.id].isSubmitted) {
            product.quantity = request[product.id].quantity;
          }
          return request[product.id] && request[product.id].isSubmitted;
        }
      );
    } else {
      filteredProducts = orderInfo.products;
    }
    const { config, meta_data } = tracking.theme;
    const sanitizer = dompurify.sanitize;
    const { lang } = user;

    // Review Step => Return / Exchange Summary => {returnCount} items
    let returnCount = 0;
    for (const key in request) {
      returnCount += request[key].quantity;
    }
    // Based on eligible Count For Return, removing the <div className="divider-horizontal mrg-top-20"></div> from already placed items
    const eligibleCountForReturn = filteredProducts.reduce(
      (acc, product) => acc + product.eligible_quantity_can_be_return,
      0
    );
    let showMultipleShipments = false;
    const { search, hash } = location;
    const searchParams = new URLSearchParams(search + hash);
    if (searchParams.has('awbs') && searchParams.has('security_key')) {
      showMultipleShipments = true;
    }
    const bflCheck = bfl.domains.indexOf(user.subdomain) > -1;
    const hasCollectionPoint = Object.keys(orderInfo.request).some(
      key => orderInfo.request[key].collection_point_id
    );
    const bflCountry = orderInfo?.addresses?.[0]?.country
      ? orderInfo?.addresses?.[0]?.country.toLowerCase()
      : 'ae'; // dynamically showing need help url as per country for BFL

    const userReasons = reasons => {
      return {
        isFetching: false,
        error: false,
        list: reasons?.list?.filter(item => {
          const reason = item.label?.toLowerCase();
          return !reason.includes('admin');
        })
      };
    };

    const listOfReasons = () => {
      if (this.props.exchangeConfig?.categoryBasedReason) {
        return this.state?.filteredReason;
      } else {
        return this.props.reasons;
      }
    };

    const getIsNItemsSelected = (n = 1) =>
      Object.values(request).length === n &&
      Array.from({ length: n }).every(
        (_, i) => Object.values(request)[i].reasonId
      );

    const getIsNItemsAtATime = product =>
      udfKeys.resrictReturnItems &&
      getIsNItemsSelected(Number(udfKeys.resrictReturnItems)) &&
      !request[product.id];

    const getIsOneItemAtATime = product =>
      Object.values(request).length > 0 &&
      !request[product.id] &&
      Object.values(request)[0].reasonId;

    const getIsNotReturnableShop101 = (
      product // shop 101 conditions only one item selectable with any qty at a time and once processed return request cannot create another return request for the same product
    ) =>
      user.subdomain === 'trackmyorder' &&
      product.shipment_eligible_for_return &&
      (getIsOneItemAtATime(product) ||
        product.quantity - product.eligible_quantity_can_be_return > 0);

    const showShop101RefundTxt =
      user.subdomain === 'trackmyorder' &&
      Object.values(request)?.[0]?.quantity <
        orderInfo.products.filter(
          prod => prod.id === Object.values(request)?.[0]?.itemId
        )?.[0]?.quantity;

    // Areas for BFL (or any other future brands)\
    let locations = {};
    let locationsFound = false;
    if (orderInfo.locationsFound && orderInfo.locations) {
      locations = orderInfo.locations;
      locationsFound = orderInfo.locationsFound;
    }
    const { udfKeys } = tracking.theme;
    udfKeys.titleTextClassName = udfKeys.titleTextClassName ?? '';

    // we are doing a customization where only admin users can edit address or add new address
    // const isPuma =
    //   ['puma', 'puma-test', 'thecollective', 'jockey-test'].indexOf(
    //     this.props.user.subdomain
    //   ) > -1;
    const formEditable =
      (config.form_editable && !udfKeys?.adminFormEditable) ||
      (config.form_editable &&
        udfKeys?.adminFormEditable &&
        this.state.source == 'dashboard');

    const isHamiltonCollection =
      this.props.user.subdomain === 'hamiltoncollection';

    const isShantanuNikhil = ['shantanunikhil', 'shantanunikhil-test'].includes(
      user.subdomain
    );

    return (
      <Fragment>
        <div
          className={`text-left main-container ${this.props.user.subdomain} 
          ${step.current > 1 ? 'return-form' : ''} ${
            page.appOnly ? 'webview' : ''
          } bg-white`}
        >
          {orderInfo.message && orderInfo.message === 'Shipment Not present' ? (
            <NotFound noMessage />
          ) : (
            <div className="container-fluid">
              <div className={`row ${step.current > 1 ? 'show' : 'hide'}`}>
                <div className="col-md-24 col-xs-24 hidden-xs hidden-sm">
                  <div className="row">
                    <div
                      className={`col-md-8 step-header ${
                        step.current === 1 ? 'active' : ''
                      }`}
                      onClick={e => this.backToStep(e, 1)}
                    >
                      <p className={`heading ${udfKeys.titleTextClassName}`}>
                        {strings[lang].returnSummary}
                      </p>
                      {/* <p className="sub-heading">
                      {Object.keys(request).length}{' '}
                      {`item${Object.keys(request).length <= 1 ? '' : 's'}`}
                    </p> */}
                    </div>
                    <div
                      className={`col-md-8 step-header ${
                        step.current === 2 ? 'active' : ''
                      }`}
                      onClick={this.backToAddresses}
                    >
                      <p className={`heading ${udfKeys.titleTextClassName}`}>
                        {isHamiltonCollection ? (
                          <p>{strings[lang].drop}</p>
                        ) : (
                          <p>{strings[lang].pickup}</p>
                        )}
                      </p>
                    </div>
                    <div
                      className={`col-md-8 step-header ${
                        step.current === 3 ? 'active' : ''
                      }`}
                      onClick={
                        step.reached > 2 ? e => this.backToStep(e, 3) : null
                      }
                    >
                      <p className={`heading ${udfKeys.titleTextClassName}`}>
                        {strings[lang].review}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-24 col-xs-24 visible-xs visible-sm xs-steps-container">
                  <div
                    className="xs-display-in-block pull-left cursor-pointer"
                    onClick={e => {
                      this.backToAddresses();
                      this.backToStep(e, step.current - 1);
                    }}
                  >
                    <span className="icon-arrow_back pull-left fs-20" />
                    <span className="pull-left xs-pd-left-10">
                      {strings[lang].back}
                    </span>
                  </div>
                  <span className="pos-x-center">
                    {strings[lang].stepOf(step.current)}
                  </span>
                </div>
              </div>
              <div
                className={`row row-eq-height return-container ${
                  step.current > 1
                    ? 'view-min-ht xs-view-min-ht return-no-footer'
                    : ''
                } ${page.appOnly ? 'vh-100 overflow-auto' : ''}`}
              >
                <div
                  className={`col-md-16 col-xs-24 order ${
                    step.reached > 1 ? 'col-md-8 eq-design' : 'col-md-offset-4'
                  } ${step.current !== 1 ? 'show hidden-xs hidden-sm' : ''}`}
                >
                  {!page.appOnly || bflCheck ? (
                    <div className="row">
                      <div
                        className={`col-md-24 col-xs-24 mrg-top-20 xs-mrg-top-10 xs-mrg-btm-10 text-right
                     ${step.reached >= 2 ? 'hidden' : ''}`}
                      >
                        <>
                          {meta_data && meta_data.gohome ? (
                            <a
                              className={`link-button d-inline-block${
                                page.appOnly ? ' hidden' : ''
                              }`}
                              onClick={this.goHome}
                            >
                              {strings[lang].goHome}
                            </a>
                          ) : null}
                        </>
                        {meta_data &&
                        meta_data.my_order_url &&
                        !['westside', 'westside-test'].includes(
                          this.props.user?.subdomain
                        ) ? (
                          <a
                            className="link-button mrg-left-10 d-inline-block"
                            onClick={this.goToMyOrder}
                          >
                            {udfKeys?.myOrderButton ?? strings[lang].myOrders}
                          </a>
                        ) : null}
                        {meta_data &&
                        meta_data.my_order_url &&
                        ['westside', 'westside-test'].includes(
                          this.props.user?.subdomain
                        ) ? (
                          <a
                            className="link-button mrg-left-10 d-inline-block"
                            href={meta_data.my_order_url}
                          >
                            {udfKeys?.myOrderButton ?? strings[lang].myOrders}
                          </a>
                        ) : null}
                        {bflCheck ? (
                          <button
                            onClick={() =>
                              openUrl(
                                `https://www.brandsforless.com/${
                                  user.lang === 'ar' ? 'ar' : 'en'
                                }-${bflCountry}/need-help/`,
                                '_blank'
                              )
                            }
                            className="link-button mrg-left-10 text-primary-color bg-gray fs-12"
                          >
                            <span className="visible hidden-xs">
                              {lang === 'en' ? 'Need help?' : 'تحتاج مساعدة؟'}
                            </span>
                            <img
                              className="hide visible-xs"
                              alt={'support'}
                              src={'/images/bfl-support.svg'}
                              width={24}
                            />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {action.activeIndex === -1 && !this.state.sku ? (
                    <>
                      <PlacedOrder />
                      {eligibleCountForReturn &&
                      orderInfo.returns.length > 0 &&
                      step.current === 1 ? (
                        <div className="divider-horizontal mrg-top-20"></div>
                      ) : null}
                    </>
                  ) : null}

                  <div
                    className={`row item-productInfo ${
                      step.current > 1 ? 'content-scroll' : ''
                    }`}
                  >
                    {!orderInfo.isFetching &&
                    this.state.sku &&
                    eligibleCountForReturn === 0 ? (
                      <div className="col-md-24 col-xs-24 mrg-top-30">
                        <section className="instructions-container">
                          <span className="icon-info mrg-right-10" />
                          <span>
                            There are no items eligible for return, please check
                            your My Orders section for order / return summary
                          </span>
                        </section>
                      </div>
                    ) : null}
                    {!orderInfo.isFetching &&
                    this.state.sku &&
                    eligibleCountForReturn === 0 ? null : (
                      <div className="col-md-24 col-xs-24 mrg-btm-15">
                        <span
                          className={`${isShantanuNikhil &&
                            'hide'} order-number pull-left`}
                        >
                          {strings[lang].orderId}:{' '}
                          {orderInfo.reference_number || ' '}
                        </span>
                      </div>
                    )}
                    {orderInfo.isFetching ? (
                      <div className="col-md-24 col-xs-24">
                        <section className="content preloader bd-top-width-1">
                          <MyLoader />
                        </section>
                      </div>
                    ) : (
                      <Fragment>
                        {!orderInfo.isFetching &&
                        this.state.sku &&
                        eligibleCountForReturn ===
                          0 ? null : hasMultipleShipments &&
                          shipments &&
                          showMultipleShipments &&
                          Object.keys(shipments)?.length > 0 ? (
                          Object.keys(shipments).map((key, idx) => (
                            <div className="mrg-top-20 mrg-btm-20" key={idx}>
                              <div className="pd-btm-20 pd-top-20 pd-left-20 pd-right-20 md-flex xs-flex items-center justify-between">
                                <span className="order-number">
                                  Shipment {idx + 1}{' '}
                                </span>
                                {shipments[key][0] &&
                                shipments[key].some(
                                  shipment =>
                                    shipment.shipment_eligible_for_return
                                ) &&
                                shipments[key].some(
                                  shipment =>
                                    shipment.eligible_quantity_can_be_return > 0
                                ) &&
                                shipments[key].some(
                                  shipment =>
                                    !shipment.non_return_reason ||
                                    shipment.non_return_reason === ''
                                ) ? (
                                  <div className={`action`}>
                                    <div className="inputGroup">
                                      <button
                                        className="ant-button"
                                        onClick={() =>
                                          this.onSelect(
                                            shipments[key][0]
                                              .tracking_info__shipment_uuid ??
                                              '',
                                            shipments[key][0]
                                              .tracking_info__awb ?? ''
                                          )
                                        }
                                      >
                                        {strings[lang].startReturn ??
                                          strings[lang].return}
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <MultipleShipments
                                shipments={shipments[key]}
                                meta_data={meta_data}
                                lang={user.lang ?? 'en'}
                                country_code={config?.country_code ?? ''}
                              />
                            </div>
                          ))
                        ) : (
                          <>
                            {orderInfo.lastReturnDate &&
                              udfKeys?.returnExpiresSoon && (
                                <div className="d-flex items-center full-width rounded mrg-btm-20 mrg-top-20 mrg-left-20">
                                  {/* <div className="mrg-right-10 d-flex items-center">
                                    {infoIcon('', '#D32F2F')}
                                  </div> */}
                                  <p className="text-red-900 fs-14">
                                    Expires Soon! Return Eligibility Ends on{' '}
                                    {moment(
                                      new Date(orderInfo.lastReturnDate)
                                    ).format('MMMM Do, YYYY')}
                                  </p>
                                </div>
                              )}
                            {filteredProducts.map((product, i) => {
                              return (
                                <div
                                  key={i}
                                  className={`col-md-24 col-xs-24 ${
                                    orderInfo.action.activeIndex === -1
                                      ? 'show'
                                      : orderInfo.action.activeIndex === i
                                      ? 'show'
                                      : 'hide'
                                  }`}
                                >
                                  <section
                                    className={`content ${
                                      orderInfo.action.activeIndex === i
                                        ? 'bd-top-width-1'
                                        : ''
                                    } ${
                                      request[product.id] &&
                                      request[product.id].isSubmitted
                                        ? 'active'
                                        : ''
                                    }`}
                                  >
                                    <div className="row">
                                      <div className="col-md-24 col-xs-24">
                                        {meta_data?.hide_return_product_thumbnail ? null : (
                                          <div className="item-thumbnail">
                                            {product?.product_url &&
                                            !bflCheck ? (
                                              <a
                                                href={product.product_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  src={
                                                    product.images ||
                                                    (meta_data.image_placeholder ??
                                                      '/images/apparel.svg')
                                                  }
                                                  alt="product"
                                                />
                                              </a>
                                            ) : (
                                              <img
                                                src={
                                                  product.images ||
                                                  (meta_data.image_placeholder ??
                                                    '/images/apparel.svg')
                                                }
                                                alt="product"
                                              />
                                            )}
                                          </div>
                                        )}
                                        <div className="item-info">
                                          {!orderInfo.isFetching &&
                                          this.state.sku &&
                                          eligibleCountForReturn ===
                                            0 ? null : (
                                            <div
                                              className={`${
                                                isShantanuNikhil
                                                  ? 'show'
                                                  : 'hide'
                                              }`}
                                            >
                                              <span>
                                                {strings[lang].orderId}:{' '}
                                              </span>
                                              <span className="item-productName mrg-btm-10">
                                                {orderInfo.reference_number ||
                                                  ' '}
                                              </span>
                                            </div>
                                          )}
                                          <div className="item-productName">
                                            {product.description}
                                          </div>
                                          <div className="size-block">
                                            <span className="label">SKU </span>
                                            <span className="label">
                                              {product.sku}
                                            </span>
                                          </div>
                                          {user.subdomain === 'sharepal-test' ||
                                          user.subdomain ===
                                            'sharepal' ? null : (
                                            <>
                                              <div className="size-block">
                                                <span className="label">
                                                  {strings[lang]?.qty ?? 'Qty'}{' '}
                                                </span>{' '}
                                                <span className="label">
                                                  {product.quantity}
                                                </span>
                                              </div>
                                              {product.quantity -
                                                product.eligible_quantity_can_be_return >
                                                0 && (
                                                <div className="size-block">
                                                  <span className="label">
                                                    {strings[lang]
                                                      ?.returnedQty ??
                                                      'Returned Qty'}{' '}
                                                  </span>{' '}
                                                  <span className="label">
                                                    {product.quantity -
                                                      product.eligible_quantity_can_be_return}
                                                  </span>
                                                </div>
                                              )}
                                              {udfKeys?.hideItemPrice ? null : (
                                                <>
                                                  <div
                                                    className={`${
                                                      isShantanuNikhil
                                                        ? 'fw-700'
                                                        : ''
                                                    } size-block`}
                                                  >
                                                    {orderInfo.currency ??
                                                      currencyMapper(
                                                        config.country_code
                                                      )}{' '}
                                                    {product.price}
                                                  </div>
                                                  <p>
                                                    {
                                                      strings[lang]
                                                        .allPriceShownPerItem
                                                    }
                                                  </p>
                                                </>
                                              )}
                                              {step.current === 1 && (
                                                <p
                                                  className={`${
                                                    isShantanuNikhil
                                                      ? 'hide'
                                                      : ''
                                                  }`}
                                                >
                                                  {
                                                    strings[lang]
                                                      .qtyPerOrderNote
                                                  }
                                                </p>
                                              )}
                                            </>
                                          )}
                                          {product.order_cancelled ? (
                                            <div className="size-block txt-red">
                                              {
                                                strings[lang]
                                                  .placeFreshPickupRequest
                                              }
                                            </div>
                                          ) : null}
                                          {getIsNotReturnableShop101(product) ||
                                          (subdomains.includes(
                                            // only single quantity can be selected. all abfrl domains
                                            user.subdomain
                                          ) &&
                                            getIsOneItemAtATime(product)) ||
                                          getIsNItemsAtATime(product) ? (
                                            <div className="size-block txt-red">
                                              {user.subdomain ===
                                                'trackmyorder' &&
                                              product.shipment_eligible_for_return &&
                                              product.quantity -
                                                product.eligible_quantity_can_be_return >
                                                0
                                                ? 'Any more returns will not be allowed for this product'
                                                : 'This item cannot be returned'}
                                            </div>
                                          ) : null}
                                          {product.non_return_reason ? (
                                            <div className="size-block txt-red">
                                              {bflCheck
                                                ? product.non_return_reason.replace(
                                                    'Return max days have been crossed',
                                                    'This item cannot be returned'
                                                  )
                                                : product.non_return_reason}
                                            </div>
                                          ) : null}
                                        </div>
                                        {product.shipment_eligible_for_return &&
                                        product.eligible_quantity_can_be_return >
                                          0 ? (
                                          <div
                                            className={`action ${
                                              step.current > 1
                                                ? 'right-m-20'
                                                : ''
                                            } ${
                                              action.activeIndex === -1
                                                ? 'view'
                                                : 'editing'
                                            }`}
                                          >
                                            {action.activeIndex === i ? (
                                              <Fragment>
                                                <h6 className="text-center  mrg-top-10 mrg-btm-10">
                                                  {strings[lang].quantity}
                                                </h6>
                                                <div
                                                  className={`return-quantity d-flex justify-center ${
                                                    meta_data.quantity_lock
                                                      ? 'disable-element'
                                                      : ''
                                                  }`}
                                                >
                                                  {!subdomains.includes(
                                                    // only single quantity can be selected. all abfrl domains
                                                    user.subdomain
                                                  ) && (
                                                    <div
                                                      className="dec"
                                                      onClick={e =>
                                                        this.quantityChange(
                                                          e,
                                                          'sub'
                                                        )
                                                      }
                                                    >
                                                      <span className="icon-minus" />
                                                    </div>
                                                  )}
                                                  <div className="text">
                                                    {
                                                      request[product.id]
                                                        .quantity
                                                    }
                                                  </div>
                                                  {!subdomains.includes(
                                                    // only single quantity can be selected. all abfrl domains
                                                    user.subdomain
                                                  ) && (
                                                    <div
                                                      className="inc"
                                                      onClick={e =>
                                                        this.quantityChange(
                                                          e,
                                                          'add'
                                                        )
                                                      }
                                                    >
                                                      <span className="icon-plus" />
                                                    </div>
                                                  )}
                                                </div>
                                              </Fragment>
                                            ) : request[product.id] &&
                                              request[product.id]
                                                .isSubmitted ? (
                                              <>
                                                <button
                                                  className={`ant-button ghost compact width-90 xs-width-74 mrg-btm-10 ${
                                                    step.current > 1
                                                      ? 'hide'
                                                      : 'show'
                                                  }`}
                                                  value={i}
                                                  onClick={e =>
                                                    this.removeRequest(e, i)
                                                  }
                                                >
                                                  {strings[lang].remove}
                                                </button>
                                                <button
                                                  className={`ant-button ghost compact width-90 xs-width-74 ${
                                                    step.current > 1
                                                      ? 'hide'
                                                      : 'show'
                                                  }`}
                                                  value={i}
                                                  onClick={e =>
                                                    this.editRequest(e, i)
                                                  }
                                                >
                                                  {strings[lang].back}
                                                </button>
                                              </>
                                            ) : (
                                              <div className="inputGroup">
                                                {getIsNotReturnableShop101(
                                                  product
                                                ) ||
                                                (subdomains.includes(
                                                  // only single quantity can be selected. all abfrl domains
                                                  user.subdomain
                                                ) &&
                                                  getIsOneItemAtATime(
                                                    product
                                                  )) ||
                                                getIsNItemsAtATime(
                                                  product
                                                ) ? null : (
                                                  <button
                                                    className={`ant-button width-90 xs-width-80 ${udfKeys.titleTextClassName}`}
                                                    value={i}
                                                    onClick={e =>
                                                      this.onChange(
                                                        e,
                                                        product.iteminfoextended__product_id
                                                      )
                                                    }
                                                  >
                                                    {strings[lang].return}
                                                  </button>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </Fragment>
                    )}
                    {step.current === 1 ? (
                      action.canGoToAddress && action.activeIndex === -1 ? (
                        <div className="col-md-24 col-xs-24">
                          <section className="footer">
                            <button
                              className="ant-button full-width"
                              onClick={e => this.goToStep(e, 2)}
                            >
                              {strings[lang].next}
                            </button>
                          </section>
                        </div>
                      ) : null
                    ) : null}
                  </div>
                  {action.activeIndex > -1 ? (
                    <Fragment>
                      <div className="divider-horizontal mrg-top-20"></div>
                      <h3>{strings[lang].whyYouWantReturn}</h3>
                      <RequestForm
                        onSubmit={this.addProductForReturn}
                        reasons={
                          this.state.source === 'dashboard'
                            ? listOfReasons()
                            : userReasons(
                                this.props.exchangeConfig?.categoryBasedReason
                                  ? this.state.filteredReason
                                  : this.props.reasons
                              )
                        }
                        backToList={this.backToList}
                        data={formData}
                        reasonChange={this.requestReasonChange}
                        initialValues={this.state.initialValues}
                        isCaptureImage={orderInfo.isCaptureImage}
                        fileUploadConfig={file}
                      />
                    </Fragment>
                  ) : null}
                  {step.current !== 1 ? (
                    <div className="edit-button-container">
                      <button
                        className="ant-button ghost"
                        onClick={e => {
                          this.backToAddresses();
                          this.backToStep(e, 1);
                        }}
                      >
                        {strings[lang].back}
                      </button>
                    </div>
                  ) : null}
                </div>

                <div
                  className={`col-md-8 overflow-auto overflow-x-hidden col-xs-24 address ${
                    step.reached > 1 ? 'show' : 'hide'
                  } ${
                    step.current !== 2
                      ? 'hidden-xs hidden-sm'
                      : 'bd-top-color-trans'
                  } ${action.address.isEditting ? 'pd-top-0 pd-btm-0' : ''}`}
                >
                  {action.address.isEditting ? (
                    <section className="row">
                      <AddressForm
                        onSubmit={this.addressFormSubmit}
                        back={this.backToAddresses}
                        address={Object.assign(this.state.address)}
                        pincodeChange={this.pincodeChange}
                        bflCheck={bflCheck}
                        locations={locations}
                        locationsFound={locationsFound}
                        subdomain={user?.subdomain}
                      />
                    </section>
                  ) : (
                    <>
                      <Fragment>
                        {isHamiltonCollection ? (
                          <section className="row content-scroll">
                            <div className="col-md-24 col-xs-24">
                              <h4 className="mrg-0 drop-border pd-10">
                                {strings[lang].dropInstruction}
                              </h4>
                            </div>
                          </section>
                        ) : (
                          <section className="row content-scroll">
                            <div className="col-md-24 col-xs-24">
                              <h4 className="mrg-0">
                                {bflCheck
                                  ? strings[lang]?.selectOrAddAddress ??
                                    'Select/Add Pickup Address'
                                  : strings[lang].selectReversePickupAddress}
                              </h4>
                            </div>
                            {addresses
                              .filter((_address, idx) =>
                                hasCollectionPoint ? idx !== 0 : true
                              )
                              .map((item, i) => (
                                <article
                                  key={i}
                                  className="col-md-24 col-xs-24 bd-btm-1 pd-top-15 pd-btm-15"
                                >
                                  <div className="row row-eq-height mrg-0">
                                    <div
                                      className="col-md-2 col-xs-2 cur-pointer pd-0"
                                      onClick={e =>
                                        this.addressSelection(
                                          e,
                                          hasCollectionPoint &&
                                            i <= addresses.length - 1
                                            ? i + 1
                                            : i
                                        )
                                      }
                                    >
                                      <div className="inputGroup text-center mrg-top-0">
                                        <input
                                          id={`address_${i}`}
                                          name="ndr-checkbox"
                                          className="blank"
                                          checked={
                                            action.address.select ===
                                            (hasCollectionPoint &&
                                            i <= addresses.length - 1
                                              ? i + 1
                                              : i)
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          readOnly
                                        />
                                        <label
                                          className="pre-text blank"
                                          htmlFor={`address_${i}`}
                                        >
                                          {' '}
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-19 col-xs-19 cur-pointer"
                                      onClick={e =>
                                        this.addressSelection(
                                          e,
                                          hasCollectionPoint &&
                                            i <= addresses.length - 1
                                            ? i + 1
                                            : i
                                        )
                                      }
                                    >
                                      <h3 className="text-theme item-name mrg-top-0">
                                        {item.name}
                                      </h3>
                                      <h4>{item.phone}</h4>
                                      <p className="text-gray">
                                        {item.address}
                                        <br />
                                        <p className="text-gray">
                                          {item.landmark}
                                        </p>
                                        {item.postal_code && item.postal_code}{' '}
                                        {item.city} {item.state}
                                        {item.country}
                                      </p>
                                      <p className="text-gray">
                                        {item.pincode}
                                      </p>
                                    </div>
                                    {formEditable ? (
                                      <div
                                        className={`col-md-3 col-xs-3 btn-underline text-right pd-0 ${
                                          step.current > 2 ? 'hide' : 'show'
                                        }`}
                                        onClick={e =>
                                          this.addressEdit(
                                            e,
                                            hasCollectionPoint &&
                                              i <= addresses.length - 1
                                              ? i + 1
                                              : i
                                          )
                                        }
                                      >
                                        {strings[lang].edit}
                                      </div>
                                    ) : null}
                                  </div>
                                </article>
                              ))}
                            {['jockey-test', 'jockey'].includes(
                              user.subdomain
                            ) &&
                              isSelfShipped &&
                              !config?.self_ship_allowed && (
                                <div className="col-md-24 col-xs-24 mrg-btm-10 mrg-top-10">
                                  <article className="summary-container pincode">
                                    <p>
                                      Apologies! The provided PIN code is not
                                      eligible for service. To arrange
                                      self-shipment, please
                                      {''}
                                      <a
                                        href="https://www.jockey.in/page/return-policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mrg-left-5"
                                      >
                                        click here
                                      </a>
                                      .
                                    </p>
                                  </article>
                                </div>
                              )}
                            {formEditable ? (
                              <div
                                className="col-md-24 col-xs-24 pd-top-15 pd-btm-15 text-center btn-underline"
                                onClick={e =>
                                  this.addressEdit(e, addresses.length)
                                }
                              >
                                {strings[lang].addNewAddress}
                              </div>
                            ) : null}
                          </section>
                        )}
                        <section className="edit-button-container">
                          <button
                            className={`ant-button 
                        ${
                          (action.address.select < 0 ||
                            isSelfShipped ||
                            !addresses[action.address.select]) &&
                          !isHamiltonCollection
                            ? 'disable-element'
                            : ''
                        }
                        ${
                          (config?.self_ship_allowed &&
                            isSelfShipped &&
                            !['SA', 'IT'].includes(config.country_code)) ||
                          step.current !== 2
                            ? 'hide'
                            : ''
                        }
                        ${
                          // This condition can be improved later
                          [
                            'thegivingmovement',
                            'tgm-test',
                            'redtagfashion',
                            'redtag-test'
                          ].includes(user.subdomain)
                            ? ''
                            : ['SA', 'IT'].includes(config.country_code) ||
                              isSelfShipped
                            ? 'w-50'
                            : ''
                        }
                        `}
                            onClick={e => this.goToStep(e, 3)}
                          >
                            {isShantanuNikhil ? 'Next' : strings[lang].proceed}
                          </button>
                          <button
                            className={`ant-button 
                        ${
                          ['SA', 'IT'].includes(config.country_code)
                            ? 'w-50'
                            : ''
                        }
                        ${
                          config && config.self_ship_allowed
                            ? ''
                            : 'disable-element'
                        }
                        ${
                          // This condition can be improved later
                          [
                            'thegivingmovement',
                            'tgm-test',
                            'redtagfashion',
                            'redtag-test'
                          ].includes(user.subdomain)
                            ? 'hide'
                            : ((config?.self_ship_allowed && isSelfShipped) ||
                                ['SA', 'IT'].includes(config.country_code)) &&
                              step.current === 2
                            ? 'show'
                            : 'hide'
                        }
                        `}
                            onClick={e => this.submitSelfShip(e, 3)}
                          >
                            {strings[lang].selfShip}
                          </button>
                        </section>
                        {step.current !== 2 ? (
                          <div className="inactive-overlay">
                            <div className="edit-button-container">
                              {config.form_editable ? (
                                <button
                                  className={`ant-button ${
                                    step.current >= 2 ? 'ghost' : ''
                                  }`}
                                  onClick={e => this.backToStep(e, 2)}
                                >
                                  {strings[lang].edit}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </Fragment>
                    </>
                  )}
                </div>
                <div
                  className={`col-md-8 col-xs-24 pickup ${
                    step.reached > 1 ? 'show' : 'hide'
                  } ${
                    step.current !== 3
                      ? 'hidden-xs hidden-sm'
                      : isSelfShipped
                      ? ''
                      : 'bd-top-color-trans'
                  }`}
                >
                  <section className="row content-scroll">
                    {step.current === 3 && isSelfShipped ? (
                      <Fragment>
                        <div className="col-md-24 col-xs-24 mrg-btm-50 xs-mrg-btm-0">
                          <p>{strings[lang].selfShipMode}</p>
                          <br />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(
                                config && config.self_ship_address
                              )
                            }}
                          ></div>
                          {config && config.refund_text ? (
                            <div
                              className="info-bar li-ht-18"
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(config && config.refund_text)
                              }}
                            ></div>
                          ) : null}
                          {showShop101RefundTxt ? (
                            <div className="info-bar li-ht-18">
                              Any more returns will not be allowed for this
                              product
                            </div>
                          ) : null}
                        </div>
                        {orderInfo?.isFetching ? null : (
                          <BankDetailForm className="mrg-btm-20 xs-mrg-btm-0 xs-mrg-top-10" />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {meta_data &&
                        meta_data.request_pickup_text &&
                        step.current === 3 &&
                        !isSelfShipped ? (
                          <div className="col-md-24 col-xs-24 mrg-btm-10">
                            <div
                              className="info-bar li-ht-18"
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(
                                  meta_data && meta_data.request_pickup_text
                                )
                              }}
                            ></div>
                          </div>
                        ) : null}
                        {subdomains.indexOf(user.subdomain) > -1 ? (
                          <div className="col-md-24 col-xs-24 mrg-btm-10">
                            <div className="info-bar li-ht-18 terms-condition">
                              <p className="mrg-btm-10">
                                <b>Terms and Conditions :</b>
                              </p>
                              <p className="mrg-btm-10">
                                We hope you understand that we can only accept
                                products for return, if they are not been used
                                or damaged.
                              </p>
                              <p className="mrg-btm-10">
                                Please ensure the products are with MRP tags /
                                labels, and all offer items / free gift(s)
                                associated with this order are returned along
                                with the product.
                              </p>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-24 col-xs-24 mrg-btm-10 visible-xs visible-sm">
                          {strings[lang].review}
                        </div>
                        {isShantanuNikhil && (
                          <div className="col-md-24 col-xs-24 mrg-btm-10">
                            <article className="summary-container return">
                              <div className="label">
                                {strings[lang].termsConditions}
                              </div>
                              <p className="text-gray">
                                <ul>
                                  {strings[lang].termandconditionsText.map(
                                    i => {
                                      return (
                                        <li className="mrg-btm-5 fs-14" key={i}>
                                          {i}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </p>
                            </article>
                          </div>
                        )}

                        <div className="col-md-24 col-xs-24 mrg-btm-10">
                          <article className="summary-container return">
                            <div
                              className={`label ${udfKeys.titleTextClassName}`}
                            >
                              {strings[lang].returnSummary}
                            </div>
                            <p className="text-gray">
                              {returnCount}
                              {` item${returnCount <= 1 ? '' : 's'}`}
                            </p>
                          </article>
                        </div>
                        {showShop101RefundTxt ? (
                          <div className="col-md-24 col-xs-24 mrg-btm-10">
                            <article className="summary-container return">
                              Any more returns will not be allowed for this
                              product
                            </article>
                          </div>
                        ) : null}
                        {action.address.select > -1 &&
                        addresses.length > 0 &&
                        addresses[action.address.select] &&
                        !isShantanuNikhil ? (
                          <div className="col-md-24 col-xs-24 mrg-btm-10">
                            <section className="summary-container pickup">
                              <div
                                className={`label ${udfKeys.titleTextClassName}`}
                              >
                                {strings[lang].pickupAddress}
                              </div>
                              <p className="text-gray">
                                {addresses[action.address.select].name}{' '}
                              </p>
                              <p className="text-gray">
                                {addresses[action.address.select].address}
                                <br /> {addresses[action.address.select].city}
                                {addresses[action.address.select].state}{' '}
                                {addresses[action.address.select].country}
                                <br />
                                {addresses[action.address.select].pincode}
                              </p>
                            </section>
                          </div>
                        ) : null}
                        {meta_data && meta_data.remove_pickup_date ? null : (
                          <div className="col-md-24 col-xs-24 mrg-btm-10 xs-mrg-btm-50">
                            <article className="datepicker-container">
                              <div className="label text-black">
                                {isHamiltonCollection ? (
                                  <>{strings[lang].expectedDropDate} </>
                                ) : (
                                  <>{strings[lang].expectedPickupDate} </>
                                )}

                                <sup className="text-red fs-16">*</sup>
                              </div>
                              <span
                                className={`${
                                  !this.props.orderInfo.date
                                    ? 'custom-calendar'
                                    : ''
                                }`}
                              >
                                <DatePicker
                                  className="pick-date"
                                  placeholderText={strings[lang].selectDate}
                                  onChange={this.onCalendarChange}
                                  selected={this.props.orderInfo.date}
                                  dateFormat="dd-MM-yyyy"
                                  isClearable={true}
                                  includeDates={this.state.dateMeta.map(
                                    item => new Date(item.date)
                                  )}
                                />
                              </span>
                            </article>
                          </div>
                        )}
                        {orderInfo.isFetching ? null : (
                          <BankDetailForm className="mrg-btm-20 xs-mrg-btm-10" />
                        )}
                        {udfKeys?.returnDisclaimer ? (
                          <div className="col-md-24 col-xs-24 mrg-btm-10 xs-mrg-btm-50">
                            <p className="pd-top-15">
                              {udfKeys?.returnDisclaimer}
                            </p>
                          </div>
                        ) : null}
                      </Fragment>
                    )}
                    <div className="edit-button-container">
                      <button
                        className={`col-md-24 col-xs-24 ant-button 
                  ${
                    action.address.select < 0 ||
                    (meta_data && !meta_data.remove_pickup_date
                      ? !orderInfo.date
                      : null) ||
                    orderInfo.isErrorInCOD ||
                    !orderInfo.confirmedRefundPayment
                      ? (isSelfShipped && !orderInfo.isErrorInCOD) ||
                        (isHamiltonCollection && orderInfo.date) //for hamilton isSelfShipped is false because end user should drop to nearest courier partner
                        ? ''
                        : 'disable-element'
                      : ''
                  } ${
                          step.current === 3 && !this.state.isSubmitting
                            ? ''
                            : 'disable-element'
                        } `}
                        onClick={this.submitReturn}
                      >
                        {strings[lang].requestReturn}
                      </button>
                    </div>
                  </section>
                  {step.current !== 3 ? (
                    <div className="inactive-overlay" />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

Order.propTypes = {
  user: PropTypes.object,
  tracking: PropTypes.object,
  orderInfo: PropTypes.object,
  page: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  orderInfoHandler: PropTypes.func,
  returnClkEvent: PropTypes.func,
  cbClkEvent: PropTypes.func,
  addProduct: PropTypes.func,
  removeProduct: PropTypes.func,
  returnResonsHandler: PropTypes.func,
  reasons: PropTypes.object,
  quantityChange: PropTypes.func,
  stepChange: PropTypes.func,
  actionChange: PropTypes.func,
  dateChange: PropTypes.func,
  requestForm: PropTypes.object,
  addressForm: PropTypes.object,
  addressChange: PropTypes.func,
  returnHandler: PropTypes.func,
  checkPincodeServiceability: PropTypes.func,
  preSignedUrl: PropTypes.func,
  uploadFileToS3: PropTypes.func,
  file: PropTypes.object,
  setImages: PropTypes.func,
  resetImages: PropTypes.func,
  capture_image: PropTypes.bool,
  resetReturnView: PropTypes.func,
  setStoreCreditInfo: PropTypes.func,
  uploadImageConfig: PropTypes.func,
  fileUploadCompleted: PropTypes.func,
  selectRefundMethod: PropTypes.func,
  getServiceableAreas: PropTypes.func,
  exchangeConfig: PropTypes.object
};

Order.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  page: state.page,
  reasons: state.reasons,
  exchangeConfig: state.exchangeConfig,
  file: state.file,
  requestForm: formValueSelector('request')(
    state,
    'reason',
    'subreason',
    'comment'
  ),
  addressForm: formValueSelector('address')(
    state,
    'name',
    'email',
    'address',
    'landmark',
    'city',
    'state',
    'country',
    'pincode',
    'phone'
  )
});

const mapDispatchToProps = dispatch => ({
  orderInfoHandler: query => dispatch(orderInfoHandler(query)),
  returnClkEvent: () => dispatch(returnClkEvent()),
  cbClkEvent: index => dispatch(cbClkEvent(index)),
  addProduct: query => dispatch(addProduct(query)),
  removeProduct: query => dispatch(removeProduct(query)),
  returnResonsHandler: domain => dispatch(returnResonsHandler(domain)),
  quantityChange: query => dispatch(quantityChange(query)),
  stepChange: step => dispatch(stepChange(step)),
  actionChange: query => dispatch(actionChange(query)),
  dateChange: date => dispatch(dateChange(date)),
  addressChange: addresses => dispatch(addressChange(addresses)),
  returnHandler: query => dispatch(returnHandler(query)),
  checkPincodeServiceability: query =>
    dispatch(checkPincodeServiceability(query)),
  preSignedUrl: idx => dispatch(preSignedUrl(idx)),
  uploadFileToS3: idx => dispatch(uploadFileToS3(idx)),
  setImages: data => dispatch(setImages(data)),
  resetImages: () => dispatch(resetImages()),
  uploadImageConfig: data => dispatch(uploadImageConfig(data)),
  resetReturnView: () => dispatch(resetReturnView()),
  setStoreCreditInfo: () => dispatch(setStoreCreditInfo()),
  fileUploadCompleted: () => dispatch(fileUploadCompleted()),
  selectRefundMethod: data => dispatch(selectRefundMethod(data)),
  getServiceableAreas: query => dispatch(getServiceableAreas(query))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
